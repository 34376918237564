html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Inter,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    height: 100%;
}

main, #__next {
    height: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}
.header-class {
    background-color: #fbfbfb;
    color: #9ca3af;
    text-transform: uppercase;
    padding: '0px';
}

/* experimenting with mobiscroll  */
/* .mbsc-timeline-column {
    width: 10vw !important;
  } */

  /* XXX this allows the mobiscroll popup in the schedule grid to be covered by the SuggestedOfficerList modal
    It may need to be adjusted
    see: https://mui.com/material-ui/customization/z-index/ for the mui defaults
  */
.mobiscroll_popup_in_grid {
    z-index: 1200  !important;
}

/* XXX when the parent of the mobi timeline has a height (required to make the header sticky)
   AND there aren't enough rows to fill the height, by default they expand their height to fill the space, which doesn't work with
   having set heights for the shift boxes
*/
.mbsc-timeline-resource, .mbsc-timeline-row {
    flex: 0 0 auto !important;
}


.mbsc-material.mbsc-calendar-wrapper:after {
    box-shadow: none !important;
}

 .mbsc-timeline-resource, .mbsc-timeline-resource-empty
   {
    border-left: 1px solid #cfcfcf;
    border-right: 1px solid #cfcfcf;

}

.mbsc-timeline-header {
    z-index: 10 !important;
    border-top: 1px solid #cfcfcf;

}

.mbsc-timeline-row, .mbsc-timeline-header-bg {
    border-right: 1px solid #cfcfcf;
}

.mbsc-timeline-resource-header-cont.mbsc-timeline-resource-col{
    border-left: 1px solid #cfcfcf;
}


.mbsc-timeline-resource-col {
    min-width: 200px !important;
}


.mbsc-material.mbsc-textfield-box.mbsc-select, .mbsc-material.mbsc-textfield-inner-box {
    background: #ffffff !important;
}

/* Stuff to make the mobiscroll textfield look more like the MUI one */
.mbsc-material.mbsc-textfield-wrapper {
    margin: 0 !important;
    /* box-sizing: border-box; */
    border: 1px solid rgba(156, 163, 175, 0.32) !important;
    border-radius: 4px;

}


:root {
  --toastify-color-success: #018005 !important;
}


@keyframes outline-pulsate {
    0%   { outline-color: rgba(67, 56, 202, 1); }
    100%  { outline-color: rgba(34, 40, 68, 0.5); }
}

/* hide help widget on small screens */
@media screen and (min-width: 0px) and (max-width: 600px) {
    #belfry_help_icon {
        display: none;
    }
}

/*  disable the mobiscroll-set min-width on not-big screens so the ScheduleGrid doesn't cause horizontal scroll  */
@media (max-width: 1620px) {
.mbsc-timeline-header-column, .mbsc-timeline-day {
    min-width: 0px !important;
  }
}

/* a fix to add extra space to the bottom of the timeline grid (but within the scrollable element) so the bulk select panel doesn't occlude the last rows */
.mbsc-timeline-grid {
    margin-bottom: 10vh;
}


.mbsc-timeline-event {
    padding-top: 4px !important;
}

.mbsc-timeline-row-gutter {
  height: 4px !important;
}

.mbsc-timeline-resource.mbsc-timeline-row {
    overflow-y: auto;
}

.mbsc-timeline-events-track {
    /* with the new variable height mobiscroll events, this make them use all the available space */
    display: flex;
}

.mbsc-timeline-parent.mbsc-timeline-row .mbsc-schedule-event{
    /* don't show a pointer for non-interactive aggregate hour display (DailyAggregateDisplay) */
    cursor: default;
}


.str-chat {
    --str-chat__primary-color: #3B4E99;
    --str-chat__active-primary-color: #004d40;
    --str-chat__surface-color: #f5f5f5;
    --str-chat__secondary-surface-color: #fafafa;
    --str-chat__primary-surface-color: #e0f2f1;
    --str-chat__primary-surface-color-low-emphasis: #edf7f7;
    --str-chat__border-radius-circle: 6px;
    --str-chat__avatar-background-color: #3B4E99;
    --str-chat__message-bubble-color: #3B4E99;
  }

/* this container query allows us to hide the pay info box, when it's within the selection widget, based on it's container size */
/* .general_schedule_bar {
      container-type: inline-size;
}
@container (max-width: 400px) {
  .general_schedule_bar .pay_info_box_in_selector_widget {
    display: none;
  }
} */

/* Chrome uses a weird highlight color for the date picker, this changes it to the normal light blue*/
input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
    background-color: #b1d2fc;
    color: black;
    outline: none;
}

.working_schedule_interval_selector .mbsc-datepicker {
    /* set the width so the arrows don't jump around because of the month names */
    width: 300px;
}
